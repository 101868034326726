import React from "react"

import "./loader.scss"

var Loader = props => (
  <div className={`loader-component ${props.className || ""}`}>
    <div className="lds-dual-ring" />
  </div>
)

export default Loader
