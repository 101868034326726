import React, { useState, useEffect } from "react"
import SendIcon from "mdi-react/SendIcon"

import PageBody from "../components/page-body/pageBody"
import SEO from "../components/seo"
import Banner from "../components/banner/banner"
import bg from "../images/banners/faq.png"
import BorderTitle from "./../components/border-title/borderTitle"
import Accordion from "./../components/accordion/accordion"
import Loader from "./../components/loader/loader"
import Input from "../components/input/input"
import TextArea from "../components/text-area/textArea"
import Button from "../components/button/button"
import Toast from "../components/toast/toast"
import { axios, TOAST_TIMER } from "../utils/utils"
import { faqPage } from "../utils/text"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle,
  faQuestionCircle,
  faLink
} from '@fortawesome/free-solid-svg-icons'

import "./faq.scss"
import "../components/alert/alert.css"

var Faq = props => {
  var [faqs, setFaqs] = useState([])
  var [loading, setLoading] = useState(true)
  var [firstCall, setFirstCall] = useState(true)
  var [questionTitle, setQuestionTitle] = useState("")
  var [questionTitleError, setQuestionTitleError] = useState(false)
  var [questionBody, setQuestionBody] = useState("")
  var [questionBodyError, setQuestionBodyError] = useState(false)
  var [questionEmail, setQuestionEmail] = useState("")
  var [questionEmailError, setQuestionEmailError] = useState(false)
  var [toasts, setToasts] = useState({
    submitSuccess: false,
    submitError: false,
  })

  useEffect(() => {
    if (firstCall) {
      setFirstCall(false)
      axios
        .get("/faqs")
        .then(result => result.data)
        .then(faqs => {
          var faqsMap = {}
          faqs.forEach(faq => {
            // Temp fix: Filtering: 
              // Instead of deleting the unwanted FAQ info from server, but to NOT render that info
            if (faq.category != "Financial Assistance" && faq.category != "Healthy Food & Drinks" && faq.category != "About the program" && faq.category != "How to submit a FAQ" && faq.category != "Church Pantry Information")
            {

              // Original: 
              if (faqsMap[faq.category] == null) {
                faqsMap[faq.category] = [faq]
              } else {
                faqsMap[faq.category].push(faq)
              }
           
            }
          })
          setFaqs(faqsMap)
          setLoading(false)
        })
    }
  })

  var showToast = toastName => {
    var newToasts = { ...toasts }
    newToasts[toastName] = true
    setToasts(newToasts)
    setTimeout(() => {
      newToasts = { ...toasts }
      newToasts[toastName] = false
      setToasts(newToasts)
    }, TOAST_TIMER)
  }

  var onSubmit = e => {
    e.preventDefault()
    var verified = true
    if (questionTitle.length <= 0) {
      setQuestionTitleError(true)
      verified = false
    }
    if (questionBody.length <= 0) {
      setQuestionBodyError(true)
      verified = false
    }
    if (questionEmail.length <= 0) {
      setQuestionEmailError(true)
      verified = false
    }

    if (verified) {
      setLoading(true)
      axios
        .post("faqs", { title: questionTitle, body: questionBody, contact: questionEmail })
        .then(() => {
          setLoading(false)
          showToast("submitSuccess")
          setQuestionTitle("")
          setQuestionBody("")
          {/*
          console.log("POST: ")
          console.log("body: ", questionBody)
          console.log("questionEmail: ", questionEmail)
          */}
        })
        .catch(err => {
          setLoading(false)
          showToast("submitError")
          console.log("Error submitting faq question", err)
        })
    }
  }

  return (
    <>
      <SEO title="Home" keywords={["gatsby", "application", "react"]} />
      <Toast show={toasts.submitSuccess} success>
        Question submitted!
      </Toast>
      <Toast show={toasts.submitError} error>
        Error submitting question
      </Toast>
      <div className="faq-page">
        <Banner
          src={bg}
          title={faqPage.banner.title}
          // subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
          height="27.5rem"
        />
        <PageBody className="page">
          <div className="pad-all-5">
              <div class="alert alert-info" role="alert">
                {faqPage.infoAlerts.alert1}
              </div>
          </div>
          <div className="pad-all-5">
                <hr />
                <BorderTitle >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" /> &nbsp;
                  {faqPage.pageDescription.title}
                </BorderTitle>


                <div className="category" key="q1">
                    <h3>• {faqPage.pageDescription.q1.title}</h3>
                    <div className="tab-left">
                      <div className="pad-top-10px">{faqPage.pageDescription.q1.answer} </div>

                      <br></br>
                      <p>{faqPage.pageDescription.q1.linkDescrip}</p>

                      <Button invert >
                        <div className="inside-button">
                        <div className="white-font">
                          <FontAwesomeIcon icon={faLink} size="sm" /> &nbsp;
                            <a className="class1" href="#FAQ_form">Link To Ask Your Questions</a>
                          </div>
                        </div>
                      </Button>
                      
                    </div>
                </div>

                <div className="pad-top-20px" key="q2">
                    <h3>• {faqPage.pageDescription.q2.title}</h3>
                    <div className="tab-left">
                      <div className="pad-top-10px">{faqPage.pageDescription.q2.answer}</div>
                    </div>
                </div>

                <br></br>
                  <hr />
          </div>

          <div className="pad-all-5">
                    <div class="alert alert-info" role="alert">
                    {faqPage.infoAlerts.alert2}
                    </div>
          </div>

          {loading ? (
            <Loader className="loader" />
          ) : Object.keys(faqs).length > 0 ? (
            <React.Fragment>
              <div className="faqs">
                {Object.keys(faqs).map(category => (
                  <div className="category" key={category}>
                    <BorderTitle className="cat-title">{category}</BorderTitle>
                    {faqs[category].map((answer, index) => (
                      <Accordion {...answer} />
                    ))}
                  </div>
                ))}
              </div>
              <div class="form-and-additional-resources">
              <form className="form" onSubmit={onSubmit} id="FAQ_form">
                <BorderTitle className="form-title">
                  <FontAwesomeIcon icon={faQuestionCircle} size="1x" /> &nbsp;
                  Ask a Question
                  </BorderTitle>

                <Input
                  className="question-title-input"
                  error={questionTitleError}
                  errorMessage="Please enter a question title"
                  name="Question Title"
                  placeholder="Your question title here..."
                  value={questionTitle}
                  onChange={e => {
                    setQuestionTitle(e.target.value)
                    setQuestionTitleError(false)
                  }}
                />

                <TextArea
                  error={questionBodyError}
                  errorMessage="Please enter a question body"
                  height="15rem"
                  name="Question Body"
                  placeholder="Your question body here..."
                  value={questionBody}
                  onChange={e => {
                    setQuestionBody(e.target.value)
                    setQuestionBodyError(false)
                  }}
                />
                
                <Input
                  className="question-title-input"
                  error={questionEmailError}
                  errorMessage="Please enter your phone or email"
                  name="Email or Phone"
                  placeholder="Your email or phone here so we can get back to you..."
                  value={questionEmail}
                  onChange={e => {
                    setQuestionEmail(e.target.value)
                    setQuestionEmailError(false)
                  }}
                />

                <Button className="submit" invert type="submit">
                  <div className="inside-button">SUBMIT QUESTION</div>
                  <SendIcon />
                </Button>
              </form>
                <div class="additional-resources">
                    <BorderTitle className="title">{faqPage.additionalInformation.title}</BorderTitle>

                    <br></br>
                      {faqPage.additionalInformation.body}
                    <br></br>
                      <span><a target="_blank" href={faqPage.additionalInformation.link.url}>{faqPage.additionalInformation.link.label}</a></span>
              
                    {/* Old:
                    <pre>{faqPage.additionalInformation.body} <span><a target="_blank" href={faqPage.additionalInformation.link.url}>{faqPage.additionalInformation.link.label}</a></span></pre>
                    */} 
                  </div>
              </div>
            </React.Fragment>
          ) : (
            <p>Sorry, no answers available yet...</p>
          )}
        </PageBody>
      </div>
    </>
  )
}

export default Faq
